<template>
    <div class="search">
        <div class="bg"></div>

        <div class="search-content">
            <section class="header-top">
                <div class="header-top-logo">
                    <img src="../assets/images/nav_img_logo.png" @click="$router.push('/')"/>
                </div>

                <div class="header-top-search">
                    <div class="search-input">
                        <input type="text" placeholder="请输入内容" v-model="searchKey">
                        <div class="search-btn" @click="search()">
                            <img src="../assets/images/nav_icon_btn_shousuo_defult.png" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="game-content">
                <div class="nav-content-box" v-if="is_show">
                    <ul class="nav-content-ul" id="hot-ul">
                        <li class="nav-content-item" v-for="item in gameList">
                            <a @click="jumpUrl(item.id)" class="game-box">
                                <div class="game-img">
                                    <img :src="item.ico" alt="">
                                </div>
                                <div class="game-info">
                                    <h3>{{item.game_name}}</h3>
                                    <p>{{item.game_desc}}</p>
                                </div>
                            </a>
                            <div class="open-btn">
                                <a href="#" @click="$router.push('/detail?gameId='+item.id)">开始游戏</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "Search",
    data() {
        return {
            searchKey: '',
            gameList: [],
            game_icon: 'http://app.yunjiwan.com',
            is_show: false
        }
    },
    created() {
        if(this.Public.isHttps()) {
            this.game_icon = 'https://app.yunjiwan.com'
        }
        this.searchKey = this.$route.query.search
        this.getGameList()
    },
    methods: {
        search() {
            if(this.searchKey) {
                this.gameList = [];
                this.getGameList()
            } else {
                this.$message({
                    type: "warning",
                    message: "请输入游戏关键词进行搜索！"
                })
            }
        },
        async getGameList() {
            this.h5Request({
                url: '/api/h5/game_list/?key='+this.searchKey
            }).then(result => {
                if(result.data.code == 0)
                {
                    if(result.data.data.items.length > 0 ){
                        result.data.data.items.forEach((item, value) => {
                            item.ico = this.game_icon + item.ico;
                            this.gameList.push(item)
                        })
                        this.is_show = true
                    }
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
    .el-message {
        max-width: 80% !important;
    }
    .game-box {
        display: flex;
    }

    .game-box .game-img img {
        width: 0.833rem;
        height: 0.833rem;
    }

    .game-box .game-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        margin-left: 0.21rem;
    }

    .game-box .game-info h3 {
        font-size: 0.21875rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .game-box .game-info p{
        width: 80%;
        margin-top: 0.167rem;
        font-size: 0.125rem;
        font-family: Microsoft YaHei;
        color: #A1A1A1;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .open-btn {
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .open-btn a {
        display: inline-block;
        width: 0.875rem;
        height: 0.4375rem;
        line-height: 0.4375rem;
        background: #FF7431;
        border-radius: 16px;
        font-size: 0.167rem;
        font-family: Microsoft YaHei;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
    }

    .nav-content-ul li {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 10px;
        margin-top: 25px;
    }

    .search {
        position: relative;
        width: 100%;
        z-index: 1;

        .bg {
            position: absolute;
            width: 100%;
            height: 0.50rem;
            background: #000333;
            z-index: -1;
        }

        .search-content {
            padding: 10px 1.35rem;
            .header-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: .42rem;

                .header-top-logo {
                    width: 1.41rem;
                    height: .31rem;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .header-top-search {
                    display: flex;

                    .search-input {
                        display: flex;
                        align-items: center;
                        input {
                            width: 2.08rem;
                            height: .31rem;
                            line-height: .31rem;
                            border-top-left-radius: .167rem;
                            border-bottom-left-radius: .167rem;
                            text-indent: .147rem;
                            font-size: .12rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FF7431;
                            border: none;
                        }

                        .search-btn {
                            display: flex;
                            align-items: center;
                            height: .31rem;
                            background: #FFFFFF;
                            border-top-right-radius: .167rem;
                            border-bottom-right-radius: .167rem;
                            box-sizing: content-box;
                            padding-right: 0.0521rem;
                            img {
                                width: .25rem;
                                height: .25rem;
                            }
                        }
                    }

                    .header-login {
                        display: flex;
                        align-items: center;
                        margin-left: 0.156rem;

                        .el-avatar {
                            height: .42rem !important;
                            width: .42rem !important;
                            line-height: .42rem !important;
                        }

                        span {
                            font-size: 0.167rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-left: .1rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .search {
            .search-content {
                padding: 10px;
                .header-top {
                    height: 30px;

                    .header-top-logo {
                        width: 3.2rem;
                        height: 100%;
                    }

                    .header-top-search {
                        height: 100%;
                        .search-input {
                            width: 100%;
                            height: 100%;
                            input {
                                width: 4.3rem;
                                height: 100%;
                            }
                            .search-btn {
                                height: 100%;
                                img {
                                    width: .6rem;
                                    height: .6rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>